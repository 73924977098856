import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';

function ChatBot() {
    
    const playSound = () => {
        const sound = document.getElementById("beep");
        sound.currentTime = 0; // Rewind to start
        sound.play(); // Play sound
      };

    // Initial messages to start each session
    const [messages, setMessages] = useState([
        { role: 'user', content: "Hey! How are you?" },
        { role: 'user', content: "Can you chat right now?" },
        { role: 'assistant', content: "Hey! Sure." },
        { role: 'assistant', content: "What's up?" },
    ]);
    const [userInput, setUserInput] = useState('');
    const [isTyping, setIsTyping] = useState(false);
    const messagesEndRef = useRef(null);
    const [isModalOpen, setIsModalOpen] = useState(false);

    // Function to handle sending a message
    const handleSendMessage = async () => {
        if (!userInput.trim()) return; // Ignore empty messages

        // Add the user's message to the conversation history
        const updatedMessages = [...messages, { role: 'user', content: userInput }];
        setMessages(updatedMessages);
        setUserInput(''); // Clear the input field
        setIsTyping(true); // Set typing indicator

        try {
            // Send the entire conversation to the backend API
            const response = await axios.post('/api/chat', { messages: updatedMessages });
            const botMessage = { role: 'assistant', content: response.data.response };

            // Add the bot's response to the chat history
            setMessages((prevMessages) => [...prevMessages, botMessage]);
        } catch (error) {
            console.error('Error sending message:', error);
            setMessages((prevMessages) => [...prevMessages, { role: 'assistant', content: 'Failed to send message' }]);
        } finally {
            setIsTyping(false); // Turn off typing indicator
        }
    };

    const handleAboutClick = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false); // Close modal
    };
    
    // Scroll to the bottom when a new message is added
    useEffect(() => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, [messages]);

    
    return (
        <div className="chat-container">
            <div className="messages">
                {messages.map((msg, index) => (
                    <div key={index} className={`message-container ${msg.role}`}>
                        {msg.role === 'assistant' && (
                            <img    src="/avatar.png"
                                    alt="Bot Avatar"
                                    className="avatar"/>
                        )}
                        {msg.role === 'user' && (
                            <img    src="/avatar2.png"
                                    alt="User Avatar"
                                    className="avatar"/>)}

                        <div className={`message ${msg.role}`}>{msg.content}</div>
                    </div>
                ))}

                {isTyping && (
                    <div className="message-container assistant" style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                        <img    src="/avatar.png"
                                alt="Bot Avatar"
                                className="avatar"/>

                        <div className="message assistant">
                            <div className="typing-indicator">
                                <span className="dot"></span>
                                <span className="dot"></span>
                                <span className="dot"></span>
                            </div>
                        </div>
                    </div>
                )}
                {/* Dummy div to track the end of messages for scrolling */}
                <div ref={messagesEndRef}/>
            </div>
    
            {/* Input area for user message and send button */}
            <div className="input-area">
            <button
                onClick={() => {
                    handleAboutClick();
                    playSound();
                }}
                style={{
                    backgroundColor: '#9999ff',
                    color: 'white',
                    fontSize: '12px',
                    border: 'none',
                    borderRadius: '30px',
                    marginRight: '10px'
                }}
            >
            Click
            </button>

            {isModalOpen && (
                <div className="modal-overlay">
                    <div className="modal">
                        <p>Text a Friend is made with MongoDB Atlas, Express, React, Node, NLP API, hosted on AWS. :) </p>
                        <button 
                            onClick={closeModal}
                            style={{
                                backgroundColor: '#ffb3bf',
                            }}
                        >Close</button>
                    </div>
                </div>
            )}
                <input  type="text"
                        value={userInput}
                        onChange={(e) => setUserInput(e.target.value)}
                        onKeyDown={(e) => e.key === 'Enter' && handleSendMessage()}/>
                <button onClick={handleSendMessage}>Send</button>
            </div>
            <audio id="beep" src="beep.wav" preload="auto"></audio>
        </div>
    );
}

export default ChatBot;

